import { UploadIcon } from 'lucide-react';
import React from 'react';
import { useDropzone, FileRejection, DropEvent, Accept } from 'react-dropzone';

// https://blog.logrocket.com/drag-and-drop-in-react/

interface DropZoneProps {
  accept?: any;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => void;
}

export default function DropZone({ onDrop, accept }: DropZoneProps) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept,
    });
  return (
    <div
      {...getRootProps()}
      className={`
      w-full  rounded border-2 h-28 flex cursor-pointer  ${
        isDragActive
          ? 'bg-gray-200 border-neutral-800  border-solid'
          : 'bg-gray-100 border-gray-300  border-dashed'
      }
      `}
      role="button"
    >
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center my-auto text-cool-gray-600 w-full">
        <UploadIcon className="w-5 h-5 text-center m-auto mb-2" />
        {isDragActive ? (
          <p className="text-center text-2base">Arrastre las imágenes</p>
        ) : (
          <p className="text-center text-2base mx-6">
            Arrastre las imágenes o haga click acá para buscarlas
          </p>
        )}
      </div>
    </div>
  );
}
