import React from 'react';
import { CheckCircle2Icon, FileIcon } from 'lucide-react';
import { Progress } from '../../ui/progress';

interface FileUploadProps {
  uploading?: boolean;
  name: string;
  src: string;
}

export default function FileUpload({
  uploading = false,
  name,
  src,
}: FileUploadProps) {
  const [progress, setProgress] = React.useState(0);

  const extractFileName = () => {
    if (!src) return '';
    const parts = src?.split('/');
    return parts[parts.length - 1];
  };

  const [fileName, setFileName] = React.useState<string>('');

  React.useEffect(() => {
    // uploading
    if (uploading) {
      const timer = setTimeout(() => setProgress(100), 500);
      return () => clearTimeout(timer);
    }
    // uploaded
    if (!uploading) {
      setFileName(extractFileName());
      const timer = setTimeout(() => setProgress(13), 500);
      return () => clearTimeout(timer);
    }
  }, [uploading]);

  React.useEffect(() => {
    setFileName(extractFileName());
  }, [src]);

  return (
    <div className="flex flex-col rounded-lg w-full border-neutral-200 border p-6">
      <div className="flex flex-row items-center justify-start">
        <FileIcon className="w-10 h-10" />
        <div className="flex flex-col items-start justify-start ml-4">
          <p className="text-md font-medium text-neutral-600 truncate w-[300px]">
            {fileName}
          </p>
          <p className="text-base text-neutral-500" />
        </div>
      </div>
      {/* progress and number */}
      <div className="flex flex-row items-center justify-between w-full gap-4">
        <Progress value={progress} className="w-full h-2 text-primary-600" />
        <p className="text-base text-neutral-500">{progress}%</p>
      </div>
      <div className="absolute top-4 right-4 ">
        <CheckCircle2Icon className="w-4 h-4 text-success-500" />
      </div>
    </div>
  );
}
